// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/render/project/src/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-a-demo-js": () => import("/opt/render/project/src/src/pages/book-a-demo.js" /* webpackChunkName: "component---src-pages-book-a-demo-js" */),
  "component---src-pages-get-in-touch-js": () => import("/opt/render/project/src/src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovation-js": () => import("/opt/render/project/src/src/pages/innovation.js" /* webpackChunkName: "component---src-pages-innovation-js" */),
  "component---src-pages-our-story-js": () => import("/opt/render/project/src/src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/render/project/src/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/opt/render/project/src/src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thanks-js": () => import("/opt/render/project/src/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

